var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h1",
    {
      staticStyle: {
        "text-align": "center",
        color: "blue",
        "font-size": "40px",
      },
    },
    [_vm._v("\n  Chức năng dành cho chủ quản lý trường\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }