<template>

  <div>
    <div v-if="autoImg">
      <img
          :src="getImagePath"
          style="width: 100%"
          alt="Hình ảnh"
      />
    </div>
    <div>
      <DashboardPlus v-if="getAppTypeUserLogin === 'plus'"/>
      <DashboardTeacher v-else-if="getAppTypeUserLogin === 'teacher'"/>
      <DashboardAdmin v-else-if="getAppTypeUserLogin === 'supperSys'"/>
      <DashboardSupperPlus v-else-if="getAppTypeUserLogin === 'supperPlus'"/>
      <DashboardParent v-else-if="getAppTypeUserLogin === 'parent'"/>
    </div>
  </div>
</template>

<script>
import DashboardPlus from "./DashboardPlus.vue";
import DashboardTeacher from "./DashboardTeacher.vue";
import DashboardAdmin from "./DashboardAdmin.vue";
import DashboardSupperPlus from "./DashboardSupperPlus.vue";
import DashboardParent from "./DashboardParent.vue";
import checkPermission from "@/utils/permission";
import {mapState} from "vuex";

export default {
  components: {
    DashboardPlus,
    DashboardTeacher,
    DashboardAdmin,
    DashboardSupperPlus,
    DashboardParent,
  },
  data() {
    return {
      // imageUrl:'@/assets/images/logoDashboard.png',
      imageUrl: 'https://media.onekids.edu.vn/sysfiles/other/dashboard_m.onekids.png',
      autoImg: true,

    };
  },
  created() {
    this.$store.dispatch('dashBoardStore/fetchDataImgUrls');
  },
  computed: {
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
    ...mapState('dashBoardStore', ['imgUrls', 'imgForPlus', 'imgForTeacher', 'imgForParent']),
    getImagePath() {
      const appType = this.getAppTypeUserLogin;
      if (appType === 'plus' && this.imgForPlus !== "" && this.imgForPlus != null) {
        return this.imgForPlus;
      } else if (appType === 'teacher' && this.imgForTeacher !== "" && this.imgForTeacher != null) {
        return this.imgForTeacher;
      } else if (appType === 'parent' && this.imgForParent !== "" && this.imgForParent != null) {
        return this.imgForParent;
      } else if (this.imgUrls.length > 0) {

        if (appType === 'plus') {
          let plus = this.imgUrls.filter(x => x.plusStatus)
          return plus[plus.length - 1].link;
        }
        if (appType === 'teacher') {
          let teacher = this.imgUrls.filter(x => x.teacherStatus)
          return teacher[teacher.length - 1].link;
        }
        if (appType === 'parent') {
          let parent = this.imgUrls.filter(x => x.parentStatus)
          return parent[parent.length - 1].link;
        }
        return this.imgUrls[this.imgUrls.length - 1].link;
      }
      return this.imageUrl;
    },
  },
  beforeMount() {
    if(this.getAppTypeUserLogin === 'supperSys' || this.getAppTypeUserLogin === 'supperPlus'){
      this.autoImg = false
    }

    if (this.checkPermission(['chartView'])) {
      if(
          !this.checkPermission(['chartView_plan_go_to_school'])
          && !this.checkPermission(['chartView_student_status_and_attendance'])
          && !this.checkPermission(['chartView_tuition_invoice'])
          && !this.checkPermission(['chartView_tuition_invoice2'])
          && !this.checkPermission(['chartView_list_grade_and_class'])
          && !this.checkPermission(['chartView_student_information_in_school'])
      ){
        this.autoImg = true;
      }else {
        setTimeout(() => {
          this.autoImg = false;
        }, 2000);
      }
    }
    if (!this.checkPermission(['chartView'])){
      this.autoImg = true;
    }

  },
  methods: {
    checkPermission,
  },

};
</script>
