var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkPermission(["chartView"])
    ? _c(
        "div",
        [
          _vm.checkPermission(["chartView_student_information_in_school"])
            ? _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content-over bg-over-1" }, [
                      _c("div", { staticClass: "content-line-1 color-1" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Tổng số học sinh: " +
                            _vm._s(_vm.statisticalData.kidsTotal.total) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-1" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh đang học: " +
                            _vm._s(_vm.statisticalData.kidsTotal.study) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-1" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh bảo lưu: " +
                            _vm._s(_vm.statisticalData.kidsTotal.reserve) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-1" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh chờ học: " +
                            _vm._s(_vm.statisticalData.kidsTotal.wait) +
                            "\n        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content-over bg-over-2" }, [
                      _c("div", { staticClass: "content-line-1 color-2" }, [
                        _c("i", { staticClass: "el-icon-document-checked" }),
                        _vm._v(
                          "\n          Đã điểm danh: " +
                            _vm._s(
                              _vm.statisticalData.attendanceTotal.attendaceYes
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-2" }, [
                        _c("i", { staticClass: "el-icon-document-delete" }),
                        _vm._v(
                          "\n          Chưa điểm danh: " +
                            _vm._s(
                              _vm.statisticalData.attendanceTotal.attendaceNo
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-2" }, [
                        _c("i", { staticClass: "el-icon-tableware" }),
                        _vm._v(
                          "\n          Đã điểm danh ăn:\n          " +
                            _vm._s(
                              _vm.statisticalData.attendanceTotal
                                .attendanceEatYes
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-2" }, [
                        _c("i", { staticClass: "el-icon-tableware" }),
                        _vm._v(
                          "\n          Chưa điểm danh ăn:\n          " +
                            _vm._s(
                              _vm.statisticalData.attendanceTotal
                                .attendanceEatNo
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content-over bg-over-3" }, [
                      _c("div", { staticClass: "content-line-1 color-3" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh đi học: " +
                            _vm._s(_vm.statisticalData.leaveTotal.goSchool) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-3" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh đã về: " +
                            _vm._s(_vm.statisticalData.leaveTotal.leaveYes) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-3" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh chưa về: " +
                            _vm._s(_vm.statisticalData.leaveTotal.leaveNo) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-3" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh đón muộn: " +
                            _vm._s(_vm.statisticalData.leaveTotal.pickupLater) +
                            "\n        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content-over bg-over-4" }, [
                      _c("div", { staticClass: "content-line-1 color-4" }, [
                        _c("i", { staticClass: "el-icon-chat-line-square" }),
                        _vm._v(
                          "\n          Số lời nhắn: " +
                            _vm._s(_vm.statisticalData.otherTotal.message) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-4" }, [
                        _c("i", { staticClass: "el-icon-edit-outline" }),
                        _vm._v(
                          "\n          Số dặn thuốc: " +
                            _vm._s(_vm.statisticalData.otherTotal.medicine) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-4" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          "\n          Số học sinh nghỉ học: " +
                            _vm._s(_vm.statisticalData.otherTotal.absent) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "content-line-1 color-4" }, [
                        _c("i", { staticClass: "el-icon-document-remove" }),
                        _vm._v(
                          "\n          Đơn xin nghỉ chưa duyệt:\n          " +
                            _vm._s(
                              _vm.statisticalData.otherTotal
                                .absentLetterNoConfirm
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.checkPermission(["chartView_plan_go_to_school"])
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "bg-purple" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(
                        "\n        Dự kiến đi học " +
                          _vm._s(_vm.learnFutureList[0].text) +
                          " -\n        " +
                          _vm._s(
                            _vm._f("formatDayMonth")(
                              _vm.learnFutureList[0].date
                            )
                          ) +
                          ":\n        " +
                          _vm._s(_vm.learnFutureList[0].number) +
                          "\n      "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "bg-purple" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(
                        "\n        Dự kiến đi học " +
                          _vm._s(_vm.learnFutureList[1].text) +
                          " -\n        " +
                          _vm._s(
                            _vm._f("formatDayMonth")(
                              _vm.learnFutureList[1].date
                            )
                          ) +
                          ":\n        " +
                          _vm._s(_vm.learnFutureList[1].number) +
                          "\n      "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "bg-purple" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(
                        "\n        Dự kiến đi học " +
                          _vm._s(_vm.learnFutureList[2].text) +
                          " -\n        " +
                          _vm._s(
                            _vm._f("formatDayMonth")(
                              _vm.learnFutureList[2].date
                            )
                          ) +
                          ":\n        " +
                          _vm._s(_vm.learnFutureList[2].number) +
                          "\n      "
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "bg-purple" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(
                        "\n        Dự kiến đi học " +
                          _vm._s(_vm.learnFutureList[3].text) +
                          " -\n        " +
                          _vm._s(
                            _vm._f("formatDayMonth")(
                              _vm.learnFutureList[3].date
                            )
                          ) +
                          ":\n        " +
                          _vm._s(_vm.learnFutureList[3].number) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.checkPermission(["chartView_student_status_and_attendance"])
            ? _c("div", { staticClass: "chartView" }, [
                _c(
                  "div",
                  { staticClass: "chartPie" },
                  [
                    _vm.loaded
                      ? _c("ChartPie", {
                          attrs: {
                            chartData: _vm.dataConllection.chartData,
                            options: _vm.dataConllection.options,
                            checkLable: true,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "chartAttendace" },
                  [
                    _vm.loaded
                      ? _c("ChartLine", {
                          attrs: {
                            chartData: _vm.dataConllection1.chartData,
                            options: _vm.dataConllection1.options,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "chartFees" }, [
            _vm.checkPermission(["chartView_tuition_invoice"])
              ? _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "button-left-status",
                        staticStyle: { width: "130px" },
                        attrs: { placeholder: "Trạng thái", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.searchByPropertiesFees()
                          },
                        },
                        model: {
                          value: _vm.kidsStatus,
                          callback: function ($$v) {
                            _vm.kidsStatus = $$v
                          },
                          expression: "kidsStatus",
                        },
                      },
                      _vm._l(
                        this.$funcData.getKidStatusAllList(),
                        function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { value: item.key, label: item.value },
                          })
                        }
                      ),
                      1
                    ),
                    _vm.loaded
                      ? _c("ChartBar", {
                          attrs: {
                            checkLable: true,
                            chartData: _vm.dataConllection2.chartData,
                            options: _vm.dataConllection2.options,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.checkPermission(["chartView_tuition_invoice2"])
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: { change: _vm.handleChangeComputed },
                            model: {
                              value: _vm.checkComputed,
                              callback: function ($$v) {
                                _vm.checkComputed = $$v
                              },
                              expression: "checkComputed",
                            },
                          },
                          [_vm._v("Đã hoàn thành")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "margin-right": "30px" },
                            on: { change: _vm.handleChangeNotComputed },
                            model: {
                              value: _vm.checkNotComputed,
                              callback: function ($$v) {
                                _vm.checkNotComputed = $$v
                              },
                              expression: "checkNotComputed",
                            },
                          },
                          [_vm._v("Chưa hoàn\n          thành\n        ")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-common",
                            staticStyle: {
                              width: "180px",
                              "margin-right": "15px",
                            },
                            attrs: {
                              placeholder: "Trạng thái hóa đơn",
                              clearable: "",
                            },
                            on: { change: _vm.handleChangeChartOrder },
                            model: {
                              value: _vm.dataSearchChart.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataSearchChart, "category", $$v)
                              },
                              expression: "dataSearchChart.category",
                            },
                          },
                          _vm._l(_vm.categories, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { value: item.value, label: item.name },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "130px" },
                            attrs: { placeholder: "Trạng thái", clearable: "" },
                            on: { change: _vm.handleChangeKidStatus },
                            model: {
                              value: _vm.kidStatusForStatistic,
                              callback: function ($$v) {
                                _vm.kidStatusForStatistic = $$v
                              },
                              expression: "kidStatusForStatistic",
                            },
                          },
                          _vm._l(
                            _vm.listKidStatusForStatistic,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { value: item.key, label: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showChartOrder
                      ? _c("ChartBar", {
                          attrs: {
                            checkLable: true,
                            chartData: _vm.dataConnectionOrder.chartData,
                            options: _vm.dataConnectionOrder.options,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                    _vm.showChartOrderComputed
                      ? _c("ChartBar", {
                          attrs: {
                            checkLable: true,
                            chartData:
                              _vm.dataConnectionOrderComputed.chartData,
                            options: _vm.dataConnectionOrderComputed.options,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                    _vm.showChartOrderNotComputed
                      ? _c("ChartBar", {
                          attrs: {
                            checkLable: true,
                            chartData:
                              _vm.dataConnectionOrderNotComputed.chartData,
                            options: _vm.dataConnectionOrderNotComputed.options,
                            height: 250,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.checkPermission(["chartView_list_grade_and_class"])
            ? _c(
                "span",
                {
                  staticStyle: {
                    color: "#6aa5e3",
                    "margin-bottom": "5px",
                    "text-decoration": "underline",
                    float: "right",
                    cursor: "pointer",
                  },
                  on: { click: _vm.showTableData },
                },
                [
                  _vm._v(
                    _vm._s(!this.showTable ? "Xem thêm >>" : "Ẩn chi tiết >>")
                  ),
                ]
              )
            : _vm._e(),
          _c("br"),
          _vm.showTable
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.responseDataList,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "120px",
                              prop: "className",
                              label: "Lớp",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "110px",
                              prop: "gradeName",
                              label: "Khối",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "130px",
                              prop: "kidsTotalNumber",
                              label: "Tổng số HS",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "attendanceYes",
                              label: "Đã điểm danh",
                              "min-width": "130px",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "attendanceNo",
                              label: "Chưa điểm danh",
                              "min-width": "130px",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goSchoolNumber",
                              label: "Đi học",
                              "min-width": "100px",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "absentNumber",
                              label: "Nghỉ học",
                              "min-width": "100px",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "leaveYes",
                              "min-width": "100px",
                              label: "Đã về",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "leaveNo",
                              "min-width": "100px",
                              label: "Chưa về",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }