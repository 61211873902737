import http from "../http-common";
class DashboardService {
  getStatisticalInSchool() {
    return http.get("/home/statistical");
  }
  getStatisticalInClass() {
    return http.get("/home/statistical/class");
  }
  getLearnFutureService() {
    return http.get("/home/learn/future");
  }

  getAllChartOrder(createdYear,category,kidStatus){
    return http.get(`/fn/chart/statistic/tuitionChart?createdYear=${createdYear}&category=${category}&kidStatus=${kidStatus}`)
  }
}

export default new DashboardService();