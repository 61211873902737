var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.autoImg
      ? _c("div", [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.getImagePath, alt: "Hình ảnh" },
          }),
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _vm.getAppTypeUserLogin === "plus"
          ? _c("DashboardPlus")
          : _vm.getAppTypeUserLogin === "teacher"
          ? _c("DashboardTeacher")
          : _vm.getAppTypeUserLogin === "supperSys"
          ? _c("DashboardAdmin")
          : _vm.getAppTypeUserLogin === "supperPlus"
          ? _c("DashboardSupperPlus")
          : _vm.getAppTypeUserLogin === "parent"
          ? _c("DashboardParent")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }