<template>
  <div v-if="checkPermission(['chartView'])">
    <el-row
        :gutter="50"
        v-if="checkPermission(['chartView_student_information_in_school'])"
    >
      <el-col :span="6">
        <div class="grid-content-over bg-over-1">
          <div class="content-line-1 color-1">
            <i class="el-icon-user-solid"></i>
            Tổng số học sinh: {{ statisticalData.kidsTotal.total }}
          </div>
          <div class="content-line-1 color-1">
            <i class="el-icon-user-solid"></i>
            Số học sinh đang học: {{ statisticalData.kidsTotal.study }}
          </div>
          <div class="content-line-1 color-1">
            <i class="el-icon-user-solid"></i>
            Số học sinh bảo lưu: {{ statisticalData.kidsTotal.reserve }}
          </div>
          <div class="content-line-1 color-1">
            <i class="el-icon-user-solid"></i>
            Số học sinh chờ học: {{ statisticalData.kidsTotal.wait }}
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content-over bg-over-2">
          <div class="content-line-1 color-2">
            <i class="el-icon-document-checked"></i>
            Đã điểm danh: {{ statisticalData.attendanceTotal.attendaceYes }}
          </div>
          <div class="content-line-1 color-2">
            <i class="el-icon-document-delete"></i>
            Chưa điểm danh: {{ statisticalData.attendanceTotal.attendaceNo }}
          </div>
          <div class="content-line-1 color-2">
            <i class="el-icon-tableware"></i>
            Đã điểm danh ăn:
            {{ statisticalData.attendanceTotal.attendanceEatYes }}
          </div>
          <div class="content-line-1 color-2">
            <i class="el-icon-tableware"></i>
            Chưa điểm danh ăn:
            {{ statisticalData.attendanceTotal.attendanceEatNo }}
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content-over bg-over-3">
          <div class="content-line-1 color-3">
            <i class="el-icon-user-solid"></i>
            Số học sinh đi học: {{ statisticalData.leaveTotal.goSchool }}
          </div>
          <div class="content-line-1 color-3">
            <i class="el-icon-user-solid"></i>
            Số học sinh đã về: {{ statisticalData.leaveTotal.leaveYes }}
          </div>
          <div class="content-line-1 color-3">
            <i class="el-icon-user-solid"></i>
            Số học sinh chưa về: {{ statisticalData.leaveTotal.leaveNo }}
          </div>
          <div class="content-line-1 color-3">
            <i class="el-icon-user-solid"></i>
            Số học sinh đón muộn: {{ statisticalData.leaveTotal.pickupLater }}
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content-over bg-over-4">
          <div class="content-line-1 color-4">
            <i class="el-icon-chat-line-square"></i>
            Số lời nhắn: {{ statisticalData.otherTotal.message }}
          </div>
          <div class="content-line-1 color-4">
            <i class="el-icon-edit-outline"></i>
            Số dặn thuốc: {{ statisticalData.otherTotal.medicine }}
          </div>
          <div class="content-line-1 color-4">
            <i class="el-icon-user-solid"></i>
            Số học sinh nghỉ học: {{ statisticalData.otherTotal.absent }}
          </div>
          <div class="content-line-1 color-4">
            <i class="el-icon-document-remove"></i>
            Đơn xin nghỉ chưa duyệt:
            {{ statisticalData.otherTotal.absentLetterNoConfirm }}
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
        :gutter="20"
        v-if="checkPermission(['chartView_plan_go_to_school'])"
    >
      <el-col :span="6">
        <div class="bg-purple">
          <i class="el-icon-user-solid"></i>
          Dự kiến đi học {{ learnFutureList[0].text }} -
          {{ learnFutureList[0].date | formatDayMonth }}:
          {{ learnFutureList[0].number }}
        </div>
      </el-col>
      <el-col :span="6"
      >
        <div class="bg-purple">
          <i class="el-icon-user-solid"></i>
          Dự kiến đi học {{ learnFutureList[1].text }} -
          {{ learnFutureList[1].date | formatDayMonth }}:
          {{ learnFutureList[1].number }}
        </div>
      </el-col
      >
      <el-col :span="6"
      >
        <div class="bg-purple">
          <i class="el-icon-user-solid"></i>
          Dự kiến đi học {{ learnFutureList[2].text }} -
          {{ learnFutureList[2].date | formatDayMonth }}:
          {{ learnFutureList[2].number }}
        </div>
      </el-col
      >
      <el-col :span="6"
      >
        <div class="bg-purple">
          <i class="el-icon-user-solid"></i>
          Dự kiến đi học {{ learnFutureList[3].text }} -
          {{ learnFutureList[3].date | formatDayMonth }}:
          {{ learnFutureList[3].number }}
        </div>
      </el-col
      >
    </el-row>
    <div
        class="chartView"
        v-if="checkPermission(['chartView_student_status_and_attendance'])"
    >
      <div class="chartPie">
        <ChartPie
            v-if="loaded"
            :chartData="dataConllection.chartData"
            :options="dataConllection.options"
            :checkLable="true"
            :height="250"
        ></ChartPie>
      </div>
      <div class="chartAttendace">
        <ChartLine
            v-if="loaded"
            :chartData="dataConllection1.chartData"
            :options="dataConllection1.options"
            :height="250"
        ></ChartLine>
      </div>
    </div>

    <div class="chartFees">
      <div
          v-if="checkPermission(['chartView_tuition_invoice'])"
          style="text-align: right">
        <el-select
            class="button-left-status"
            v-model="kidsStatus"
            placeholder="Trạng thái"
            clearable
            style="width: 130px"
            @change="searchByPropertiesFees()"
        >
          <el-option
              v-for="item in this.$funcData.getKidStatusAllList()"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>
        <ChartBar
            v-if="loaded"
            :checkLable="true"
            :chartData="dataConllection2.chartData"
            :options="dataConllection2.options"
            :height="250"
        ></ChartBar>
      </div>

      <div

          v-if="checkPermission(['chartView_tuition_invoice2'])"
      >
        <div style="text-align: right">
          <el-checkbox v-model="checkComputed" @change="handleChangeComputed">Đã hoàn thành</el-checkbox>
          <el-checkbox v-model="checkNotComputed" @change="handleChangeNotComputed" style="margin-right: 30px">Chưa hoàn
            thành
          </el-checkbox>
          <el-select
              @change="handleChangeChartOrder"
              style="width: 180px; margin-right: 15px"
              class="input-common"
              v-model="dataSearchChart.category"
              placeholder="Trạng thái hóa đơn"
              clearable
          >
            <el-option
                v-for="item in categories"
                :key="item.key"
                :value="item.value"
                :label="item.name"
            ></el-option>
          </el-select>

          <!--          Hoành thêm: Thêm trạng thái học sinh-->

          <el-select
              v-model="kidStatusForStatistic"
              placeholder="Trạng thái"
              style="width: 130px"
              @change="handleChangeKidStatus"
              clearable
          >
            <el-option
                v-for="(item,index) in listKidStatusForStatistic"
                :key="index"
                :value="item.key"
                :label="item.value"
            ></el-option>
          </el-select>


        </div>
        <ChartBar
            v-if="showChartOrder"
            :checkLable="true"
            :chartData="dataConnectionOrder.chartData"
            :options="dataConnectionOrder.options"
            :height="250"
        ></ChartBar>
        <ChartBar
            v-if="showChartOrderComputed"
            :checkLable="true"
            :chartData="dataConnectionOrderComputed.chartData"
            :options="dataConnectionOrderComputed.options"
            :height="250"
        ></ChartBar>
        <ChartBar
            v-if="showChartOrderNotComputed"
            :checkLable="true"
            :chartData="dataConnectionOrderNotComputed.chartData"
            :options="dataConnectionOrderNotComputed.options"
            :height="250"
        ></ChartBar>

      </div>
    </div>

    <span
        @click="showTableData"
        v-if="checkPermission(['chartView_list_grade_and_class'])"
        style="
        color: #6aa5e3;
        margin-bottom: 5px;
        text-decoration: underline;
        float: right;
        cursor: pointer;
      "
    >{{ !this.showTable ? "Xem thêm >>" : "Ẩn chi tiết >>" }}</span
    >
    <br/>
    <el-row v-if="showTable">
      <el-col>
        <el-table
            :data="responseDataList"
            highlight-current-row
            :header-cell-style="tableHeaderColor"
            :max-height="$tableMaxHeight"
            border
        >
          <el-table-column
              type="index"
              label="STT"
              width="50"
              align="center"
          ></el-table-column>
          <el-table-column
              min-width="120px"
              prop="className"
              label="Lớp"
          ></el-table-column>
          <el-table-column
              min-width="110px"
              prop="gradeName"
              label="Khối"
          ></el-table-column>

          <el-table-column
              width="130px"
              prop="kidsTotalNumber"
              label="Tổng số HS"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="attendanceYes"
              label="Đã điểm danh"
              min-width="130px"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="attendanceNo"
              label="Chưa điểm danh"
              min-width="130px"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="goSchoolNumber"
              label="Đi học"
              min-width="100px"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="absentNumber"
              label="Nghỉ học"
              min-width="100px"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="leaveYes"
              min-width="100px"
              label="Đã về"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="leaveNo"
              min-width="100px"
              label="Chưa về"
              align="center"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import CommonOnekidsService from "@/services/CommonService/CommonOnekidsService";
import ChartStatisticalService from "@/services/ChartStatisticalService";
import ChartPie from "../chartStatistical/chart/ChartPie.vue";
import ChartLine from "../chartStatistical/chart/ChartLine.vue";
import ChartBar from "../chartStatistical/chart/ChartBar.vue";
import {mapState} from "vuex";
import checkPermission from "@/utils/permission";
import moment from "moment/moment";

export default {
  components: {ChartPie, ChartLine, ChartBar},

  data() {
    return {
      categories: [
        {
          id: 1,
          value: "CLASS",
          name: "Tất cả lớp"
        },
        {
          id: 2,
          value: "GROUP",
          name: "Tất cả nhóm"
        },
      ],

      kidStatusForStatistic: "",
      listKidStatusForStatistic: [
        {key: "STUDYING", value: "Đang học"},
        {key: "STUDY_WAIT", value: "Chờ học"},
        {key: "RESERVE", value: "Bảo lưu"},
        {key: "LEAVE_SCHOOL", value: "Nghỉ học"},
        {key: "OUT_SCHOOL", value: "Ra trường"},
      ],
      dataSearchChart: {
        category: ""
      },
      showTable: false,

      dataConllection: {},
      dataConllection1: {},
      dataConllection2: {},
      dataConnectionOrder: {},
      dataConnectionOrderComputed: {},
      dataConnectionOrderNotComputed: {},

      loaded: false,
      loadChart: false,
      dataSearch: {
        type: "date",
        dateList: [
          moment().subtract(29, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      kidsStatus: "",
      dataLabels: [],
      dataFeesLabels: [],
      dataList: [1, 2, 3, 4],
      dataList1: [860, 1140, 1060, 1060, 1070, 1110, 1330, 2210, 7830, 2478],
      dataList2: [1600, 1700, 1700, 1900, 2000, 2700, 4000, 5000, 6000, 7000],
      dataList3: [300, 700, 2000, 5000, 6000, 4000, 2000, 1000, 200, 100],
      dataList4: [300, 700, 2000, 5500, 6000, 3000, 2000, 1000, 200, 100],
      dataListOrder1: [],
      dataListOrder2: [],
      dataListOrderComputed1: [],
      dataListOrderComputed2: [],
      dataListOrderComputed3: [],
      dataListOrderComputed4: [],
      dataListOrderNotComputed1: [],
      dataListOrderNotComputed2: [],
      dataFeesList1: [],
      dataFeesList2: [],
      dataFeesList3: [],
      tableDataFilter: [],
      statisticalData: {
        kidsTotal: "",
        attendanceTotal:"",
        leaveTotal:"",
        otherTotal:""
      },
      responseDataList: [],
      learnFutureList: [],
      checkComputed: false,
      checkNotComputed: false,
      showChartOrder: true,
      showChartOrderComputed: false,
      showChartOrderNotComputed: false,
      category: "",
      year: moment().format("YYYY")
    };
  },
  async mounted() {
    if (this.checkPermission(['chartView'])
    ) {
      this.fillDataAll();
      this.fillData();
      this.fillDataFees();
      this.loaded = false;
      this.dataSearchChart.category = ""
      await this.searchByProperties();
      await this.searchByPropertiesAttendance();
      await this.searchByPropertiesFees();
      if (this.checkPermission(['chartView_tuition_invoice2'])) {
        await this.searchChartOrder(this.year, this.dataSearchChart.category)
      }
    }

  },
  created() {
    sessionStorage.removeItem("breadcrumbList");
    this.$store.dispatch("layout/setBreadcrumbActions", []);
    let appTypeUser = this.$store.state.auth.user.appType;
    if (appTypeUser == "parent") {
      this.$alert(
          "Tính năng giành cho Phụ huynh đang được cập nhật",
          "Thông báo!",
          {
            confirmButtonText: "OK",
          }
      );
      localStorage.removeItem("user");
      this.$router.push("/login");
    }
  },
  watch: {
    updateDashBoard: {
      handler() {
        this.fetchData()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('layout', ['updateDashBoard']),
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
  },
  methods: {
    checkPermission,
    // set for hearder table
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold";
    },
    showTableData() {
      this.showTable = !this.showTable;
    },

    getDataInitialInSchool() {
      DashboardService.getStatisticalInSchool()
          .then((resp) => {
            this.statisticalData = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    fillDataAll() {
      this.dataConllection = {
        chartData: {
          labels: ["Đang học", "Chờ học", "Bảo lưu", "Nghỉ học"],
          datasets: [
            {
              backgroundColor: ["#c694f9", "#6aa5e3", "#feb683", "#ef5b5b"],
              data: this.dataList,
              hoverBorderWidth: 2,
              hoverBorderColor: "#6f6f6f",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Trạng thái học sinh",
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            datalabels: {
              color: "#fff",
              anchor: "end",
              align: "start",
              borderRadius: 25,
              borderColor: "#fff",
              borderWidth: 2,
              backgroundColor: (ctx) => {
                return ctx.dataset.backgroundColor;
              },
              font: {
                size: 11,
                weight: "bold",
              },
              formatter: (value, ctx) => {
                let sum = this.loda.sumBy(ctx.dataset.data);
                return ((value * 100) / sum).toFixed(1) + "%";
              },
            },
          },
        },
      };
    },
    fillData() {
      this.dataConllection1 = {
        chartData: {
          labels: this.dataLabels,
          datasets: [
            {
              label: "Đi học",
              data: this.dataList1,
              borderColor: "#6aa5e3",
              fill: false,
            },
            {
              label: "Nghỉ có phép",
              data: this.dataList2,
              borderColor: "orange",
              fill: false,
            },
            {
              label: "Nghỉ không phép",
              data: this.dataList3,
              borderColor: "#ef5b5b",
              fill: false,
            },
            {
              label: "Chưa điểm danh",
              data: this.dataList4,
              borderColor: "black",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          fontSize: 11,
          title: {
            display: true,
            text: `Tình trạng điểm danh (${this.moment(
                String(this.dataSearch.dateList[0])
            ).format("DD/MM/YYYY")} - ${this.moment(
                String(this.dataSearch.dateList[1])
            ).format("DD/MM/YYYY")})`,
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            zoom: {
              zoom: {
                enabled: true,
                mode: "x",
                speed: 1,
                modifierKey: "ctrl",
              },
              pan: {
                enabled: true,
                mode: "x",
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },
    fillDataFees() {
      this.dataConllection2 = {
        chartData: {
          labels: this.dataFeesLabels,
          datasets: [
            {
              label: "Đã hoàn thành",
              data: this.dataFeesList1,
              backgroundColor: "#6aa5e3",
            },
            {
              label: "Chưa hoàn thành",
              data: this.dataFeesList2,
              backgroundColor: "#fd4803",
            },
            {
              label: "Chưa có khoản",
              data: this.dataFeesList3,
              backgroundColor: "#fb9a00",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Hóa đơn học phí",
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return value > 0 ? value : "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },
    searchByProperties() {
      this.loadChart = true;
      ChartStatisticalService.findAllChartKidsStatus()
          .then((resp) => {
            this.dataList = resp.data.data;
            this.loaded = true;
            this.fillDataAll();
          })
          .catch(() => {
            this.$message({
              message: "Failed to get the data",
              type: "error",
            });
            this.loadChart = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.loadChart = false;
            }, 500);
          });
    },
    /**
     * tìm kiếm tất cả khoản của các học sinh
     */
    searchByPropertiesAttendance() {
      this.loadChart = true;
      ChartStatisticalService.findAttendanceKids(
          "",
          "",
          this.dataSearch.type,
          this.dataSearch.dateList,
          "",
          "",
          ""
      )
          .then((resp) => {
            let results = resp.data.data;
            let dataLabels = [];
            let attendance = [];
            let attendanceYes = [];
            let attendanceNo = [];
            var attendanceUn = [];
            for (let i = 0; i < results.length; i++) {
              const e = results[i];
              dataLabels.push(e.name);
              attendance.push(e.attendance);
              attendanceYes.push(e.attendanceYes);
              attendanceNo.push(e.attendanceNo);
              attendanceUn.push(e.attendanceUn);
            }
            this.dataLabels = dataLabels;
            this.dataList1 = attendance;
            this.dataList2 = attendanceYes;
            this.dataList3 = attendanceNo;
            this.dataList4 = attendanceUn;
            this.loaded = true;
            this.fillData();
          })
          .catch(() => {
            this.$message({
              message: "Failed to get the data",
              type: "error",
            });
            this.loadChart = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.loadChart = false;
            }, 500);
          });
    },
    /**
     * tìm kiếm tất cả khoản của các học sinh
     */
    searchByPropertiesFees() {
      this.loadChart = true;
      ChartStatisticalService.findFeesKids(
          this.moment().get("year"),
          this.kidsStatus
      )
          .then((resp) => {
            let results = resp.data.data;
            let dataLabels = [];
            let feesYes = [];
            let feesNo = [];
            let feesUn = [];
            for (let i = 0; i < results.length; i++) {
              const e = results[i];
              dataLabels.push(e.name);
              feesYes.push(e.feesYes);
              feesNo.push(e.feesNo);
              feesUn.push(e.feesUn);
            }
            this.dataFeesLabels = dataLabels;
            this.dataFeesList1 = feesYes;
            this.dataFeesList2 = feesNo;
            this.dataFeesList3 = feesUn;
            this.loaded = true;
            this.fillDataFees();
          })
          .catch(() => {
            this.$message({
              message: "Failed to get the data",
              type: "error",
            });
            this.loadChart = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.loadChart = false;
            }, 500);
          });
    },
    getDataInitialInClass() {
      DashboardService.getStatisticalInClass()
          .then((resp) => {
            this.responseDataList = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getLearnFutureMethod() {
      DashboardService.getLearnFutureService()
          .then((resp) => {
            this.learnFutureList = resp.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getApiMethod() {
      CommonOnekidsService.getApiAgain()
          .then((resp) => {
            let user = JSON.parse(localStorage.getItem("user"));
            user.apiSet = resp.data.data;
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.dispatch("auth/setUserActions", user);
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    fetchData() {
      this.getDataInitialInSchool();
      this.getDataInitialInClass();
      this.getLearnFutureMethod();
      this.getApiMethod();
    },

    handleChangeComputed() {

      if (this.checkComputed === true) {
        this.checkNotComputed = false
        this.showChartOrder = false
        this.showChartOrderComputed = true
        this.showChartOrderNotComputed = false
        this.searchChartOrder(this.year, this.dataSearchChart.category)
      } else {
        this.showChartOrder = true
        this.showChartOrderComputed = false
      }
    },

    handleChangeNotComputed() {
      if (this.checkNotComputed === true) {
        this.checkComputed = false
        this.showChartOrder = false
        this.showChartOrderComputed = false
        this.showChartOrderNotComputed = true
        this.searchChartOrder(this.year, this.dataSearchChart.category)
      } else {
        this.showChartOrder = true
        this.showChartOrderNotComputed = false
      }
    },

    handleChangeChartOrder() {
      if (this.checkComputed === false && this.checkNotComputed === false) {
        this.searchChartOrder(this.year, this.dataSearchChart.category)
      } else if (this.checkComputed === true) {
        this.handleChangeComputed()
      } else if (this.checkNotComputed === true) {
        this.handleChangeNotComputed()
      }
    },

    handleChangeKidStatus() {
      this.handleChangeChartOrder()
    },

   async searchChartOrder(year, category) {
     let kidStatus = this.kidStatusForStatistic
     await DashboardService.getAllChartOrder(year, category, kidStatus).then((response) => {
        this.resetListChart()
        for (let i = 0; i < response.data.length; i++) {
          const e = response.data[i];
          this.dataListOrder1.push(e.complete)
          this.dataListOrder2.push(e.noComplete)
          this.dataListOrderComputed1.push(e.yesMoneyNextMonth)
          this.dataListOrderComputed2.push(e.yesMoneyPaymentFull)
          this.dataListOrderComputed3.push(e.yesPaymentOverallMoney)
          this.dataListOrderComputed4.push(e.noPaymentNextMonth)
          this.dataListOrderNotComputed1.push(e.noMoneyPaymentFull)
          this.dataListOrderNotComputed2.push(e.noMoneyPayment)
        }
       this.fillDataOrder()
       this.fillDataOrderComputed()
       this.fillDataOrderNotComputed()
      }).catch(() => {
        this.$message({
          message: "Failed to get the data",
          type: "error",
        });
        this.loadChart = false;
      })
    },

    resetListChart() {
      this.dataListOrder1 = []
      this.dataListOrder2 = []
      this.dataListOrderComputed1 = []
      this.dataListOrderComputed2 = []
      this.dataListOrderComputed3 = []
      this.dataListOrderComputed4 = []
      this.dataListOrderNotComputed1 = []
      this.dataListOrderNotComputed2 = []
    },

    fillDataOrderComputed() {
      this.dataConnectionOrderComputed = {
        chartData: {
          labels: this.$funcData.getListMonth(),
          datasets: [
            {
              label: "Đóng đủ thừa",
              data: this.dataListOrderComputed1,
              backgroundColor: "#6aa5e3",
            },
            {
              label: "Đóng đủ 100%",
              data: this.dataListOrderComputed2,
              backgroundColor: "#e16244",
            },
            {
              label: "Đóng thừa",
              data: this.dataListOrderComputed3,
              backgroundColor: "#0bfd03",
            },
            {
              label: "Đóng đủ thiếu",
              data: this.dataListOrderComputed4,
              backgroundColor: "#aa03fd",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Hóa đơn học phí đã hoàn thành",
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return value > 0 ? value : "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },

    fillDataOrderNotComputed() {
      this.dataConnectionOrderNotComputed = {
        chartData: {
          labels: this.$funcData.getListMonth(),
          datasets: [
            {
              label: "Chưa đóng đủ",
              data: this.dataListOrderNotComputed1,
              backgroundColor: "#6ae3b7",
            },
            {
              label: "Chưa đóng 100%",
              data: this.dataListOrderNotComputed2,
              backgroundColor: "#9903fd",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Hóa đơn học phí chưa hoàn thành",
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return value > 0 ? value : "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },

    fillDataOrder() {
      this.dataConnectionOrder = {
        chartData: {
          labels: this.$funcData.getListMonth(),
          datasets: [
            {
              label: "Đã hoàn thành",
              data: this.dataListOrder1,
              backgroundColor: "#6aa5e3",
            },
            {
              label: "Chưa hoàn thành",
              data: this.dataListOrder2,
              backgroundColor: "#fd4803",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Tổng hợp hóa đơn học phí",
            fontSize: 15,
            fontColor: "#000",
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return value > 0 ? value : "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },

  },
  beforeMount() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.class-flex {
  display: flex;
  flex-direction: row;
}

/deep/ .el-table td {
  padding: 6px 0;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .el-col {
    border-radius: 4px;

    .grid-content-over {
      font-size: 17px;
      border-radius: 10px;
      height: 150px;
      color: white;
    }

    .el-table {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: 1px solid #78a5e7;
    }
  }
}

.content-line {
  padding: 12px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.content-line-1 {
  padding: 6px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.color-1 {
  background: #c694f9;
}

.color-2 {
  background: #6aa5e3;
}

.color-3 {
  background: #feb683;
}

.color-4 {
  background: #ef5b5b;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-pagination {
  text-align: right;
  margin: 30px 0;
}

/deep/ .el-pagination .el-select .el-input {
  width: 50px;
}

.chartView {
  display: flex;
  flex-direction: row;
}

.chartPie {
  width: 30%;
}

.chartAttendace {
  width: 70%;
}

.chartFees {
  margin-bottom: 20px;
  margin-top: 10px;
}

.bg-purple {
  background: #e99191;
  padding: 5px;
  font-size: 17px;
  border-radius: 10px;
}
</style>
