var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkPermission(["chartView"])
    ? _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 50 } },
            [
              _c(
                "el-col",
                { staticStyle: { color: "white" }, attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-1" }, [
                      _c("i", { staticClass: "el-icon-chat-line-square" }),
                      _vm._v(
                        "\n        Số lời nhắn: " +
                          _vm._s(_vm.statisticalData.otherTotal.message) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { color: "white" }, attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-2" }, [
                      _c("i", { staticClass: "el-icon-edit-outline" }),
                      _vm._v(
                        "\n        Số dặn thuốc: " +
                          _vm._s(_vm.statisticalData.otherTotal.medicine) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { color: "white" }, attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-3" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(
                        "\n        Số học sinh nghỉ học: " +
                          _vm._s(_vm.statisticalData.otherTotal.absent) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { color: "white" }, attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-4" }, [
                      _c("i", { staticClass: "el-icon-document-remove" }),
                      _vm._v(
                        "\n        Đơn xin nghỉ chưa duyệt:\n        " +
                          _vm._s(
                            _vm.statisticalData.otherTotal.absentLetterNoConfirm
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.responseDataList,
                        "highlight-current-row": "",
                        "row-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "120px",
                          prop: "className",
                          label: "Lớp",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "min-width": "110px",
                          prop: "gradeName",
                          label: "Khối",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "130px",
                          prop: "kidsTotalNumber",
                          label: "Tổng số HS",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "attendanceYes",
                          label: "Đã điểm danh",
                          "min-width": "130px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "attendanceNo",
                          label: "Chưa điểm danh",
                          "min-width": "130px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goSchoolNumber",
                          label: "Đi học",
                          "min-width": "100px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "absentNumber",
                          label: "Nghỉ học",
                          "min-width": "100px",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "leaveYes",
                          "min-width": "100px",
                          label: "Đã về",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "leaveNo",
                          "min-width": "100px",
                          label: "Chưa về",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }